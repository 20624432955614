import { Fees } from '@app/models';

export interface State {
  data: Fees;
  isLoading?: boolean;
  error?: any;
  exportId: number;
}

export const initialState: State = {
  data: null,
  isLoading: false,
  error: null,
  exportId: null,
};
