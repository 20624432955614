import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FeesStoreEffects } from './effects';
import { FeesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('fees', FeesReducer),
    EffectsModule.forFeature([FeesStoreEffects]),
  ],
})
export class FeesStoreModule {}
