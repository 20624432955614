import { createAction, props } from '@ngrx/store';
import { FeesExportRequest } from '../../models/requests/fees-request';

export const loadFeesRequest = createAction(
  '[Fees] Load Request',
  props<{
    propertiesIds: number[];
    invoice_layout_id: number[];
    date_from: string;
    date_to: string;
    invoice_types?: string[];
    view?: 'block' | 'unique';
  }>(),
);

export const loadFeesSuccess = createAction(
  '[Fees] Load Success',
  props<{ fees: any }>(),
);

export const loadFeesFailure = createAction(
  '[Fees] Load Failure',
  props<{ error: any }>(),
);

export const setFeesExpand = createAction(
  '[Fees] Set Expand',
  props<{
    vatCode?: string;
    year: number;
    expand: boolean;
    month: number;
    day: number;
  }>(),
);

export const setFeesExpandAll = createAction(
  '[Fees] Set Expand All',
  props<{
    expand: boolean;
    viewType: 'block' | 'unique';
  }>(),
);

export const exportRequest = createAction(
  '[Fees] Export Request',
  props<FeesExportRequest>(),
);

export const exportSuccess = createAction(
  '[Fees] Export Success',
  props<{ exportId: number }>(),
);

export const exportFailure = createAction(
  '[Fees] Export Failure',
  props<{ error: any }>(),
);

export const resetFeesState = createAction('[Fees] Reset State');
