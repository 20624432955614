import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FeesService } from '../../services';

import * as fromActions from './actions';
import { ExportService } from '../../services/export.service';

@Injectable()
export class FeesStoreEffects {
  constructor(
    private dataService: FeesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private exportService: ExportService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFeesRequest),
      switchMap(
        ({
          invoice_layout_id,
          date_from,
          date_to,
          propertiesIds,
          ...options
        }) => {
          return this.dataService
            .load(invoice_layout_id, date_from, date_to, propertiesIds, options)
            .pipe(
              map((response: IResponseSuccess) =>
                fromActions.loadFeesSuccess({ fees: response.data }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.loadFeesFailure(error));
              }),
            );
        },
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportRequest),
      switchMap((params) => {
        return this.dataService.export(params).pipe(
          map(
            (response: {
              data: { export_ids: number[] } | { viewUrl: string };
            }) => {
              const { data } = response;

              if ('export_ids' in data) {
                return fromActions.exportSuccess({
                  exportId: data.export_ids[0],
                });
              }

              this.exportService.directExport(data.viewUrl);

              return fromActions.exportSuccess({
                exportId: null,
              });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.exportFailure(error));
          }),
        );
      }),
    ),
  );
}
