import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getData = (state: State) => state.data;

export const getExportId = (state: State) => state.exportId;

export const selectFeesState = createFeatureSelector<State>('fees');

export const selectFeesData = createSelector(selectFeesState, getData);

export const selectExportId = createSelector(selectFeesState, getExportId);

export const selectFeesError = createSelector(selectFeesState, getError);

export const selectFeesIsLoading = createSelector(
  selectFeesState,
  getIsLoading,
);
