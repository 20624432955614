import { Action, createReducer, on } from '@ngrx/store';
import { merge, omit } from 'lodash';

import * as fromActions from './actions';
import * as fromState from './state';

const noExpandKeys = [
  'vat_quote_values',
  'property_name',
  'taxes',
  'nets',
  'total_taxes',
  'total_nets',
  'total_fees',
  'number_documents',
  'layout_name',
  'total_documents',
  'expand',
];

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadFeesRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadFeesSuccess, (state, { fees }) => ({
    ...state,
    data: fees,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadFeesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(
    fromActions.setFeesExpand,
    (state, { vatCode, year, expand, month, day }) => {
      let newData = {};
      if (day) {
        newData = merge({}, newData, {
          [year]: {
            [month]: {
              [day]: {
                expand,
              },
            },
          },
        });
      } else if (month) {
        newData = merge({}, newData, {
          [year]: {
            [month]: {
              expand,
            },
          },
        });
      } else {
        newData = merge({}, newData, {
          [year]: {
            expand,
          },
        });
      }

      if (vatCode) {
        newData = { [vatCode]: newData };
      }

      return merge({}, state, { data: newData });
    },
  ),
  on(fromActions.setFeesExpandAll, (state, { expand, viewType }) => {
    let data = omit(state.data, ...noExpandKeys);

    if (viewType === 'block') {
      Object.keys(data).forEach((vatCode) => {
        const vatCodeData = omit(data[vatCode], ...noExpandKeys);
        Object.keys(vatCodeData).forEach((year) => {
          const yearData = omit(data[vatCode][year], ...noExpandKeys);
          Object.keys(yearData).forEach((month) => {
            const monthData = omit(data[vatCode][year][month], ...noExpandKeys);
            Object.keys(monthData).forEach((day) => {
              data = merge({}, data, {
                [vatCode]: {
                  [year]: {
                    [month]: {
                      expand,
                      [day]: {
                        expand,
                      },
                    },
                  },
                },
              });
            });
          });
        });
      });
    }

    if (viewType === 'unique') {
      Object.keys(data).forEach((year) => {
        const yearData = omit(data[year], ...noExpandKeys);
        Object.keys(yearData).forEach((month) => {
          const monthData = omit(data[year][month], ...noExpandKeys);
          Object.keys(monthData).forEach((day) => {
            data = merge({}, data, {
              [year]: {
                [month]: {
                  expand,
                  [day]: {
                    expand,
                  },
                },
              },
            });
          });
        });
      });
    }

    return merge({}, state, { data });
  }),

  on(fromActions.exportSuccess, (state, { exportId }) => {
    return {
      ...state,
      exportId,
    };
  }),

  on(fromActions.resetFeesState, () => fromState.initialState),
);

export function FeesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
